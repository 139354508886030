var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-app-bar',{staticStyle:{"left":"0","border-bottom":"3px #f3b228 solid"},attrs:{"app":"","color":"#fff","flat":"","height":"70"}},[_c('img',{staticStyle:{"margin":"-8px 85px 0 0"},attrs:{"src":"/yukon.svg","height":"44"}}),_c('v-toolbar-title',{staticClass:"mb-0",attrs:{"tag":"h1"}},[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(_vm._s(_vm.applicationName))]),_c('v-progress-circular',{staticClass:"ml-4",class:_vm.loadingClass,attrs:{"indeterminate":"","color":"#f3b228","size":"20","width":"2"}})],1),_c('v-spacer'),(_vm.isAuthenticated)?_c('div',[_c('v-menu',{staticClass:"ml-0",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Navigation "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,false,1223188086)},[_c('v-list',{staticStyle:{"min-width":"200px"},attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/dashboard"}},[_c('v-list-item-title',[_vm._v("Dashboard")])],1),(
								_vm.userInRole([
									_vm.UserRoles.SITE_ADMIN,
									_vm.UserRoles.SITE_EDITOR,
									_vm.UserRoles.SITE_VIEWER,
									_vm.UserRoles.SITE_VIEWER_LIMITED,
								])
							)?_c('v-list-item',{attrs:{"to":"/sites"}},[_c('v-list-item-title',[_vm._v("YHSI Sites")])],1):_vm._e(),(_vm.userInRole([_vm.UserRoles.SITE_ADMIN]))?_c('v-list-item',{attrs:{"to":"/sites-change-requests"}},[_c('v-list-item-title',[_vm._v("YHSI Site Change Requests ")])],1):_vm._e(),(
								_vm.userInRole([
									_vm.UserRoles.PHOTO_ADMIN,
									_vm.UserRoles.PHOTO_EDITOR,
									_vm.UserRoles.PHOTO_VIEWER,
								])
							)?_c('v-list-item',{attrs:{"to":"/photos"}},[_c('v-list-item-title',[_vm._v("Photos")])],1):_vm._e(),(_vm.userInRole([_vm.UserRoles.PHOTO_ADMIN, _vm.UserRoles.PHOTO_EDITOR]))?_c('v-list-item',{attrs:{"to":"/photobatches"}},[_c('v-list-item-title',[_vm._v("Photo Batches")])],1):_vm._e(),_c('v-list-item',{attrs:{"to":"/maps"}},[_c('v-list-item-title',[_vm._v("Maps")])],1),(
								_vm.userInRole([
									_vm.UserRoles.AIRPLANE_CRASH_EDITOR,
									_vm.UserRoles.AIRPLANE_CRASH_VIEWER,
								])
							)?_c('v-list-item',{attrs:{"to":"/airplane"}},[_c('v-list-item-title',[_vm._v("Airplane Crash Sites")])],1):_vm._e(),(_vm.userInRole(['Boats Editor', 'Boats Viewer']))?_c('v-list-item',{attrs:{"to":"/boats"}},[_c('v-list-item-title',[_vm._v("Boats")])],1):_vm._e(),(
								_vm.userInRole([_vm.UserRoles.BOATS_EDITOR, _vm.UserRoles.BOATS_VIEWER])
							)?_c('v-list-item',{attrs:{"to":"/burials"}},[_c('v-list-item-title',[_vm._v("Burials")])],1):_vm._e(),(
								_vm.userInRole([
									_vm.UserRoles.INTERPRETIVE_SITES_EDITOR,
									_vm.UserRoles.INTERPRETIVE_SITES_VIEWER,
								])
							)?_c('v-list-item',{attrs:{"to":"/interpretive-sites"}},[_c('v-list-item-title',[_vm._v("Interpretive Sites")])],1):_vm._e(),(_vm.userInRole([_vm.UserRoles.PLACE_EDITOR]))?_c('v-list-item',{attrs:{"to":"/places"}},[_c('v-list-item-title',[_vm._v("Places")])],1):_vm._e(),(_vm.userInRole([_vm.UserRoles.PEOPLE_EDITOR]))?_c('v-list-item',{attrs:{"to":"/people"}},[_c('v-list-item-title',[_vm._v("People")])],1):_vm._e()],1)],1),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","color":"primary","title":"Recently visited"},on:{"click":function($event){return _vm.showHistory()}}},[_c('v-icon',[_vm._v("mdi-history")])],1),_c('span',[_vm._v(_vm._s(_vm.username))]),_c('v-menu',{staticClass:"ml-0",attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,4294619505)},[_c('v-list',{staticStyle:{"min-width":"200px"},attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":"/profile"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-title',[_vm._v("My profile")])],1),(_vm.userInRole([_vm.UserRoles.ADMINISTRATOR]))?_c('v-list-item',{attrs:{"to":"/admin"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-cogs")])],1),_c('v-list-item-title',[_vm._v("Administration")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.signOut}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-run")])],1),_c('v-list-item-title',[_vm._v("Sign out")])],1)],1)],1)],1):_c('div',[_c('router-link',{attrs:{"to":"/sign-in"}},[_vm._v("Sign in")])],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('router-view'),_c('RequestAlert')],1)],1)],1)],1),_c('history-sidebar',{ref:"historySidebar"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }