export const UserRoles = {
	ADMINISTRATOR: 'Administrator',
	SITE_ADMIN: 'Site Admin',
	SITE_EDITOR: 'Site Editor',
	SITE_VIEWER: 'Site Viewer',
	PHOTO_ADMIN: 'Photo Admin',
	PHOTO_EDITOR: 'Photo Editor',
	PHOTO_VIEWER: 'Photo Viewer',
	SITE_VIEWER_LIMITED: 'Site Viewer Limited',
	AIRPLANE_CRASH_EDITOR: 'Airplane Crash Editor',
	AIRPLANE_CRASH_VIEWER: 'Airplane Crash Viewer',
	BOATS_EDITOR: 'Boats Editor',
	BOATS_VIEWER: 'Boats Viewer',
	PLACE_EDITOR: 'Places Editor',
	BURIALS_EDITOR: 'Burials Editor',
	BURIALS_VIEWER: 'Burials Viewer',
	PEOPLE_EDITOR: 'People Editor',
	YRHP_EDITOR: 'YRHP Editor',
	ADVANCED_MAPS: 'Advanced Mapping',
	INTERPRETIVE_SITES_VIEWER: 'Interpretive Sites Viewer',
	INTERPRETIVE_SITES_EDITOR: 'Interpretive Sites Editor',
};
